@import '~antd/dist/antd.css';
@import url('./styles/measurements.css');
@import url('./styles/colors.css');

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	margin: 0;
	height: 100%;
	min-height: 100%;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-spin-dot-item {
	background-color: var(--primary-color);
}

.ant-upload.ant-upload-drag:hover,
.ant-input-number:hover,
.ant-input-password:hover,
.ant-input:hover,
.ant-select-selector:hover, .ant-select-selector:focus-within,
.ant-picker:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: var(--primary-color) !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-password.ant-input-affix-wrapper:focus,
.ant-input:focus,
.ant-input-number-focused,
.ant-select-focused,
.ant-picker-focused {
	border-color: var(--primary-color);
	box-shadow: 0 0 0 2px rgb(253 13 13 / 25%);
}

.btn:focus {
	box-shadow: 0 0 0 0.25rem rgb(253 13 13 / 25%) !important;
}

.ant-picker-today-btn,
.btn-link {
	color: var(--primary-color);
	border: none;
	text-decoration: none;
}

.ant-tabs-tab.ant-tabs-tab-active {
	border-bottom: 2px solid var(--primary-color);
	z-index: 1;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--primary-color);
}

.ant-tabs-tab:hover,
.btn-link:hover {
	color: var(--red-hover-color);
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
}

.ant-radio-inner::after {
	background-color: var(--primary-color);
}

.ant-radio:hover .ant-radio-inner {
	border-color: var(--red-hover-color);
}

.ant-btn-primary,
.primary-btn {
	background-color: var(--primary-color);
	border: 0.5px solid var(--primary-color);
	color: var(--white-color);
}

.pry-outline-btn {
	background-color: var(--white-color);
	border: 0.5px solid var(--primary-color);
	color: var(--primary-color);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.primary-btn:hover, 
.primary-btn:active,
.primary-btn:focus {
	background-color: var(--red-hover-color);
	border: 0.5px solid var(--red-hover-color);
	color: var(--white-color);
}

.pry-outline-btn:hover, 
.pry-outline-btn:active,
.pry-outline-btn:focus {
	color: var(--red-hover-color);
	border: 0.5px solid var(--red-hover-color);
	background-color: var(--white-color);
}

.primary-btn:disabled {
	background-color: var(--disabled-color);
	border: 0.5px solid var(--disabled-color);
	color: var(--white-color);
}

.pry-outline-btn:disabled {
	color: var(--disabled-color);
	border: 0.5px solid var(--disabled-color);
	background-color: var(--white-color);
}

.primary-btn:focus-visible {
	background-color: var(--primary-color);
	border: 0.5px solid var(--primary-color);
}

.ant-menu-item:hover,
.ant-menu-submenu-selected,
.ant-menu-submenu-title:hover,
.ant-menu-submenu:hover .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-item a:hover {
	color: var(--primary-color) !important;
}

.ant-menu-item-selected {
	background-color: #ffe6ee !important;
	color: var(--primary-color);
}

.ant-menu-item::after {
	border-right: 3px solid var(--primary-color) !important;
}

.success-text {
	color: var(--success-color);
}

.wrong-text {
	color: var(--wrong-color);
}

.info-text {
	color: var(--info-color);
}

.modal {
	z-index: 1020;
}

.modal-backdrop {
	z-index: 1010;
}

.ant-notification {
	z-index: 1030;
}

.geosuggest__suggests {
	padding-left: 0.2rem;
	margin-top: 1rem;
	border-right: 1px solid #F5F5F5;
}

.progress-bar{
	background-color: var(--primary-color);
}

.lblCurrency{
	margin-right: 1rem;
}

.deleteIcon:hover,
.btnCart:hover,
.btnCart:focus{
	color: var(--primary-color);
	border-color: var(--primary-color);
}

.avatar-icon{
	background: var(--primary-color) !important;
    border-radius: 100% !important;
    color: #fff !important;
    font-size: 16px !important;
    height: 45px !important;
    line-height: normal !important;
    text-align: center !important;
    width: 45px !important;
}