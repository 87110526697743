:root {
   /* common measurements */
   --xl-measure: 3em;
   --lg-measure: 2em;
   --md-measure: 1.5em;
   --sm-measure: 1em;
   --xs-measure: 0.5em;
   /* common logo measurements */
   --default-width-measure: 5rem;
   /* default font size */
   --xl-font-size: 1.85em;
   --lg-font-size: 1.5em;
   --default-font-size: 1em;
   --md-font-size: 0.90em;
   --sm-font-size: 0.75em;
   --xs-font-size: 0.65em;
   /* Navbar height */
   --header-height: 64px;
   /* Drawer navigation */
   --drawer-height: 19em;
   /* default margins - paddings */
   --default-separation: 0.5em;

}