.container {
	height: 100%;
	width: 100%;
}

.layout-container {
	background-image: url("../../assets/images/login-register.webp");
	background-repeat: no-repeat;
  	background-size: cover;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100% - var(--header-height));
	padding: var(--default-separation);
}

.navbar {
	height: var(--header-height);
	background-color: var(--primary-color);
	box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.col-logo {
	display:flex;
	align-items: center;
	justify-content: center;
}
 
/* SM */
@media (min-width: 576px) {
   	.layout-container {
		padding: var(--default-separation);
		min-height: calc(100% - var(--header-height));
   	}
}
  /* MD */
@media (min-width: 768px) {
   	.layout-container {
		padding: var(--default-separation);
		min-height: calc(100% - var(--header-height));
   	}
}
  /* LG */
@media (min-width: 992px) {
	.layout-container {
		padding: 0 2em;
		min-height: calc(100% - var(--header-height));
   	}
}
  /* XL  */
 @media (min-width: 1200px) {
   	.layout-container {
		padding: 0 10em;
		min-height: calc(100% - var(--header-height));
   	}
}