.navbar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--primary-color);
    border-bottom: 0 solid transparent;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius:  50%;
}
