.col-form {
    text-align: center;
}

/* MD */
@media (min-width: 768px) {
    .col-form {
        text-align: end;
    }
}
/* LG */
@media (min-width: 992px) {
    .col-form {
        text-align: end;
    }
}
/* categories - categoryform modal */
.category-image {
    width: 70%;
}

.product-image {
    width: 50%;
}

.cover-image {
    width: 300px;
    height: 150px;
}
/* google */
.list-google {
    list-style: none;
}

.list-google:hover {
    background-color: #F5F5F5;
}